import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/Seo';
import { Container, Card, Col, Row } from 'reactstrap';
import Layout from '../components/layouts/Layout';
import { GatsbyImage } from 'gatsby-plugin-image';
import ReactPlayer from 'react-player';
import { useBreakpoint } from '@a1-ict/gatsby/utils/breakpoint';

const InfoPage = ({ data }) => {
  const infos = data.allStrapiInfoPages?.nodes?.[0];

  return (
    <Layout navColor="white" margined={true}>
      <SEO title={`${infos?.title}`} description={infos?.title} />
      <Container fluid="md" className="py-5">
        <h1 className="mb-4">{infos.title}</h1>
        <Card className="p-4">
          {infos.description && <div dangerouslySetInnerHTML={{ __html: infos.description }} />}
          {infos.sections.map((section, i) => {
            const description = section?.description?.replace(/&nbsp;/g, ' ');
            return (
              <div key={`term-section-${i}`} className="mb-5">
                <h3 className="mb-4">{section.title}</h3>
                <div className="info-page__video-text ">
                  {section.video_url && (
                    <div className="info-page__video-text__video text-center mx-2 mb-3 mb-md-2 mt-2 mr-sm-5">
                      <ReactPlayer width="auto" url={`${section.video_url} `} controls={false} />
                    </div>
                  )}
                  {section?.images?.length > 0 && (
                    <div
                      style={{ overflowX: 'auto', width: section?.description ? '600px' : '100%' }}
                      className="info-page__image-text__image text-center mx-2 mb-3 mb-md-2 mt-2 mr-sm-5 "
                    >
                      {/* <GatsbyImage
                                        // style={{ height: section.images.length === 1 ? "500px" : section.images.length === 2 ? "400px" : "330px" }}
                                        style={{ height: "500px", opacity: 1 }}
                                        image={{ ...section.images[0].localFile.childImageSharp.gatsbyImageData, height: 1 }}
                                        objectFit={'fill'}
                                        alt={section?.title || "Описателна снимка"} /> */}
                      {/* <div style={{ height: "100%", width: "100%", backgroundSize: "cover", backgroundPosition: "center", backgroundImage: `url('${process.env.GATSBY_BACKEND}${section.images[0].url}')` }}></div> */}
                      <img
                        style={{ width: '100%', height: '100%' }}
                        src={`${process.env.GATSBY_BACKEND}${section.images[0].url}`}
                        alt=""
                      />
                    </div>
                  )}
                  <div
                    className="info-page__video-text__text"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>

                {/* {section.images.length > 0 &&

                                <Row className="">

                                    {section.images.map(sec => {
                                        return <Col sm={12} md={section.images.length === 1 ? 12 : 6} xl={section.images.length === 1 ? 12 : section.images.length === 2 ? 6 : 4} className=" m-md-0 px-md-2  py-xs-4 px-xs-0">
                                            {breakpoints.md ? <div className="" style={{ overflowX: 'auto' }}>
                                                <img style={{ height: section.images.length === 1 ? "500px" : section.images.length === 2 ? "400px" : "330px" }} src={`${process.env.GATSBY_BACKEND}${sec.url}`} alt="" />
                                            </div> : <GatsbyImage
                                                style={{ height: section.images.length === 1 ? "500px" : section.images.length === 2 ? "400px" : "330px" }}
                                                image={{ ...sec.localFile.childImageSharp.gatsbyImageData, height: 1 }}
                                                objectFit={section.images.length === 1 ? 'fill' : 'cover'}
                                                alt={section.title} />}
                                        </Col>

                                    })}

                                </Row>
                            } */}
              </div>
            );
          })}
        </Card>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query InfoPages($id: Int, $type: String) {
    allStrapiInfoPages(filter: { strapiId: { eq: $id }, nodeType: { eq: $type } }) {
      nodes {
        title
        description
        nodeType
        strapiId
        sections {
          title
          description
          video_url
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(transformOptions: { fit: FILL }, placeholder: DOMINANT_COLOR)
              }
              publicURL
            }
            url
          }
        }
      }
    }
  }
`;

export default InfoPage;
